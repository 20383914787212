// @ts-nocheck
'use strict';
import 'whatwg-fetch';

// fix to get fosrouting via webpack, see https://github.com/symfony/webpack-encore/issues/5#issuecomment-308101272
// Use the webpack feature allowing to read some variable: https://webpack.github.io/docs/shimming-modules.html
// const { router, setRoutingData } = require('imports-loader?window=>{}!exports-loader?router=window.Routing,setData=fos.Router.setRoutingData!../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.js');
// dumped_routes.json is the output file for the fos:js-routing:dump command
// const routerConfig = require('../dumped_routes.json'); // how can this be generated through webpack? automatically?
// setRoutingData(routerConfig);
const router = window.Routing;

class Naskor {

    constructor() {

    }

    static get Routing() {
        return router;
    }
    static get loadingClass() {
        return 'is_loading';
    }

    static get ProductNatureAssortment() {
        return 2;
    }
    static get ProductNatureBundleReference() {
        return 4;
    }

    /**
     * @param {NaskorProductListProduct} product
     * @param {NaskorProductPromoSelectInfo} promoSelectInfo
     */
    static getProductUniqueId(product, promoSelectInfo) {
        if (product.nature === Naskor.ProductNatureBundleReference && promoSelectInfo) {
            let flavourName = 'all';
            if(promoSelectInfo.promo_product.product_attributes.length === 1) {
                flavourName = promoSelectInfo.promo_product.product_attributes[0].name;
            }
            return promoSelectInfo.promo_product.id + flavourName;
        }

        return product.id;
    }

    static formatPrice = (price, price_currency = 'EUR', locale = 'de-DE') => {
        let formatted = '';
        if(price !== false) {
            formatted = price.toLocaleString(locale, {style: 'currency', currency: price_currency});
        }
        return formatted;
    };

    static formatWeight = (weight) => {
        let suffix = 'g';
        if(weight > 999) {
            weight = weight / 1000;
            suffix = 'kg';
            weight = Naskor.formatNumber(weight);
        }
        return `${weight} ${suffix}`;

    };

    static formatNumber = (number, decimals = 2, locale = 'de-DE') => {
        return parseFloat(number).toLocaleString(locale, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        });
    };

    static formatDate = (date, format = 'medium' , locale = 'de-DE') => {
        if(!(date instanceof Date)) {
            date = new Date(date);
        }
        let options = {};
        // options.timeZone = "UTC"; // ETA modified to another day
        // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
        if(format === 'medium') {
            options.year = 'numeric';
            options.month = '2-digit';
            options.day = '2-digit';
        }
        return date.toLocaleDateString(locale, options);
    };

    static formatShipUnits = (ship_units, ship_type_id) => {
        let ship_type = 'Parcel';
        if(ship_type_id === 2) {
            ship_type = 'Pallet';
        }
        if(ship_units !== 1) {
            ship_type += 's';
        }
        return `${ship_units} ${ship_type}`;
    };

    static fetchJson = (url, body) => {
        let headers = new Headers();
        headers.append('X-Requested-With', 'XMLHttpRequest');
		let init = {};
        if(body) {
			init = {
				credentials: 'same-origin',
				headers: headers,
				body: body,
				method: 'POST',
			};
		} else {
			init = {
				credentials: 'same-origin',
				headers: headers,
			};
		}

        return fetch(url, init)
            .then(response => response.json())
            // .catch(data => {
            //     console.log(data);
            //     return Promise.reject(data);
            // })
            ;
    };

    static showFancyBox(divId) {
        $.fancybox.open({
            src  : '#'+divId,
            type : 'inline',
            touch: false
        });
    }
}

class NaskorEnteredQuantity {


    constructor() {
        this.quantityEnteredPerProductIdAndProductAttributeId = {};
        this.promoQuantityEnteredPerProductIdAndProductAttributeId = {};
        this.quantityReducedToMaxStockPerProductIdAndProductAttributeId = {};
        this.promoQuantityReducedToMaxStockPerProductIdAndProductAttributeId = {};
        this.quantityEnteredAutomaticallyPerProductIdAndProductAttributeId = {};
        this.promoQuantityEnteredAutomaticallyPerProductIdAndProductAttributeId = {};
        this.enteredProducts = [];
    }


    /**
     * @param quantityPerProductId
     * @param productId
     * @return {{}}
     * @private
     */
    _quantityPerProductAttribute(quantityPerProductId, productId) {
        let quantityPerProductAttributeId = {};
        if(quantityPerProductId.hasOwnProperty(productId)) {
            quantityPerProductAttributeId = quantityPerProductId[productId];
        }
        return quantityPerProductAttributeId;
    }

    // _quantityEnteredPerProductAttributeId(productId) {
    //     return this._quantityPerProductAttribute(this.quantityEnteredPerProductIdAndProductAttributeId, productId);
    // }
    // _promoQuantityEnteredPerProductAttributeId(productId) {
    //     return this._quantityPerProductAttribute(this.promoQuantityEnteredPerProductIdAndProductAttributeId, productId);
    // }
    // _quantityReducedToMaxStockPerProductAttributeId(productId) {
    //     return this._quantityPerProductAttribute(this.quantityReducedToMaxStockPerProductIdAndProductAttributeId, productId);
    // }
    // _promoQuantityReducedToMaxStockPerProductAttributeId(productId) {
    //     return this._quantityPerProductAttribute(this.promoQuantityReducedToMaxStockPerProductIdAndProductAttributeId, productId);
    // }

    /**
     * @param quantityPerProductId
     * @param productId
     * @param productAttributeId
     * @param quantity
     * @return {*}
     * @private
     */
    _enterQuantityPerProductAttribute(quantityPerProductId, productId, productAttributeId, quantity){
        if(!quantityPerProductId[productId]) {
            quantityPerProductId[productId] = {};
        }
        if(quantity !== 0) {
            quantityPerProductId[productId][productAttributeId] = quantity;
        } else {
            delete quantityPerProductId[productId][productAttributeId];
        }
        if(Object.keys(quantityPerProductId[productId]).length === 0) {
            delete quantityPerProductId[productId];
        }
        return quantityPerProductId;
    }

    /**
     * @param quantityPerProductId
     * @param productId
     * @param productAttributeId
     * @return {number}
     * @private
     */
    _quantityForProductAttributeId(quantityPerProductId, productId, productAttributeId){
        let quantity = 0;
        let quantityEnteredPerProductAttributeId = this._quantityPerProductAttribute(quantityPerProductId, productId);
        if(quantityEnteredPerProductAttributeId.hasOwnProperty(productAttributeId)) {
            quantity = quantityEnteredPerProductAttributeId[productAttributeId];
        }
        return quantity;
    }

    /**
     * @param quantityPerProductId
     * @param productId
     * @return {number}
     * @private
     */
    _sumQuantityForProductId(quantityPerProductId, productId){
        let sumQuantity = 0;
        let quantityEnteredPerProductAttributeId = this._quantityPerProductAttribute(quantityPerProductId, productId);
        for (let productAttributeId in quantityEnteredPerProductAttributeId) {
            if(quantityEnteredPerProductAttributeId.hasOwnProperty(productAttributeId)) {
                sumQuantity += quantityEnteredPerProductAttributeId[productAttributeId];
            }
        }
        return sumQuantity;
    }

    /**
     * @param quantityPerProductId
     * @param quantityReducedPerProductId
     * @param quantityAutomaticallyPerProductId
     * @param productUniqueId
     * @param productAttribute
     * @param quantity
     * @param automaticallyEntered
     * @param isCheckingStockActive
     * @param isReducedToMaxStock
     * @param requestedQuantity
     * @return {*[]}
     * @private
     */
    _enterQuantity(quantityPerProductId, quantityReducedPerProductId, quantityAutomaticallyPerProductId, productUniqueId, productAttribute, quantity, automaticallyEntered, isCheckingStockActive = true, isReducedToMaxStock = false, requestedQuantity = 0){
        quantityPerProductId = this._enterQuantityPerProductAttribute(
            quantityPerProductId,
            productUniqueId,
            productAttribute.id,
            quantity
        );
        if(isCheckingStockActive) {
            quantityReducedPerProductId = this._enterQuantityPerProductAttribute(
                quantityReducedPerProductId,
                productUniqueId,
                productAttribute.id,
                -1
            );
        }
        if(isReducedToMaxStock) {
            quantityReducedPerProductId = this._enterQuantityPerProductAttribute(
                quantityReducedPerProductId,
                productUniqueId,
                productAttribute.id,
                requestedQuantity
            );
        }
        if(!isCheckingStockActive && !isReducedToMaxStock) {
            quantityReducedPerProductId = this._enterQuantityPerProductAttribute(
                quantityReducedPerProductId,
                productUniqueId,
                productAttribute.id,
                0
            );
        }

        if(automaticallyEntered) {
            quantityAutomaticallyPerProductId = this._enterQuantityPerProductAttribute(
                quantityAutomaticallyPerProductId,
                productUniqueId,
                productAttribute.id,
                1
            );
        } else {
            quantityAutomaticallyPerProductId = this._enterQuantityPerProductAttribute(
                quantityAutomaticallyPerProductId,
                productUniqueId,
                productAttribute.id,
                0
            );
        }

        return [
            quantityPerProductId,
            quantityReducedPerProductId,
            quantityAutomaticallyPerProductId,
        ]
    }

    /**
     * @returns {NaskorProductWithProductAttributes[]}
     */
    getEnteredProducts() {
        return this.enteredProducts;
    }

    /**
     * @param {NaskorProductWithProductAttributes} product
     * @param {NaskorProductListProductAttribute} productAttribute
     * @param {number} quantity
     * @param {boolean} isCheckingStockActive
     * @param {boolean} isReducedToMaxStock
     * @param {number} requestedQuantity
     */
    enterQuantity(product, productAttribute, quantity, isCheckingStockActive = true, isReducedToMaxStock = false, requestedQuantity = 0){
        const quantityPerProductId = this.quantityEnteredPerProductIdAndProductAttributeId;
        const quantityReducedPerProductId = this.quantityReducedToMaxStockPerProductIdAndProductAttributeId;
        const quantityAutomaticallyPerProductId = this.quantityEnteredAutomaticallyPerProductIdAndProductAttributeId;

        const automaticallyEntered = false;

        const lists = this._enterQuantity(quantityPerProductId, quantityReducedPerProductId, quantityAutomaticallyPerProductId, product.id, productAttribute, quantity, automaticallyEntered, isCheckingStockActive, isReducedToMaxStock, requestedQuantity);
        this.enteredProducts[product.id] = product;

        this.quantityEnteredPerProductIdAndProductAttributeId = lists[0];
        this.quantityReducedToMaxStockPerProductIdAndProductAttributeId = lists[1];
        this.quantityEnteredAutomaticallyPerProductIdAndProductAttributeId = lists[2];
    }

    /**
     * @param {NaskorProduct} product
     * @param {NaskorProductListProductAttribute} productAttribute
     * @param {number} quantity
     * @param {NaskorProductPromoSelectInfo} promoSelectInfo
     * @param {boolean} automaticallyEntered
     * @param {boolean} isCheckingStockActive
     * @param {boolean} isReducedToMaxStock
     * @param {number} requestedQuantity
     */
    enterPromoQuantity(product, productAttribute, quantity, promoSelectInfo, automaticallyEntered, isCheckingStockActive = true, isReducedToMaxStock = false, requestedQuantity = 0){
        const quantityPerProductId = this.promoQuantityEnteredPerProductIdAndProductAttributeId;
        const quantityReducedPerProductId = this.promoQuantityReducedToMaxStockPerProductIdAndProductAttributeId;
        const quantityAutomaticallyPerProductId = this.promoQuantityEnteredAutomaticallyPerProductIdAndProductAttributeId;

        const productUniqueId = Naskor.getProductUniqueId(product, promoSelectInfo);
        const lists = this._enterQuantity(quantityPerProductId, quantityReducedPerProductId, quantityAutomaticallyPerProductId, productUniqueId, productAttribute, quantity, automaticallyEntered, isCheckingStockActive, isReducedToMaxStock, requestedQuantity);

        this.promoQuantityEnteredPerProductIdAndProductAttributeId = lists[0];
        this.promoQuantityReducedToMaxStockPerProductIdAndProductAttributeId = lists[1];
        this.promoQuantityEnteredAutomaticallyPerProductIdAndProductAttributeId = lists[2];
    }

    /**
     * @param {NaskorProduct} product
     * @param {NaskorProductListProductAttribute} productAttribute
     * @param {NaskorProductPromoSelectInfo} promoSelectInfo
     */
    isCheckingStockActiveForProductAttribute(product, productAttribute, promoSelectInfo = null){
        const productUniqueId = Naskor.getProductUniqueId(product, promoSelectInfo);
        let list = this.quantityReducedToMaxStockPerProductIdAndProductAttributeId;
        if(promoSelectInfo) {
            list = this.promoQuantityReducedToMaxStockPerProductIdAndProductAttributeId;
        }
        return -1 === this._quantityForProductAttributeId(
            list,
            productUniqueId,
            productAttribute.id,
        );
    }

    /**
     * @param {NaskorProduct} product
     * @param {NaskorProductListProductAttribute} productAttribute
     * @param {NaskorProductPromoSelectInfo} promoSelectInfo
     */
    isReducedToMaxStockForProductAttribute(product, productAttribute, promoSelectInfo = null){
        const productUniqueId = Naskor.getProductUniqueId(product, promoSelectInfo);
        let list = this.quantityReducedToMaxStockPerProductIdAndProductAttributeId;
        if(promoSelectInfo) {
            list = this.promoQuantityReducedToMaxStockPerProductIdAndProductAttributeId;
        }
        return 0 < this._quantityForProductAttributeId(
            list,
            productUniqueId,
            productAttribute.id,
        );
    }

    /**
     * @param {NaskorProduct} product
     * @param {boolean} isPromo
     */
    isCheckingStockActiveForProduct(product, isPromo = false){
        let list = this.quantityReducedToMaxStockPerProductIdAndProductAttributeId;
        if(isPromo) {
            list = this.promoQuantityReducedToMaxStockPerProductIdAndProductAttributeId;
        }


        let quantityEnteredPerProductAttributeId = this._quantityPerProductAttribute(list, product.id);
        for (let productAttributeId in quantityEnteredPerProductAttributeId) {
            if(-1 === this._quantityForProductAttributeId(
                list,
                product.id,
                productAttributeId,
            )) {
                return true;
            }
        }
        return false;
    }

    /**
     * @param {boolean} isPromo
     */
    isCheckingStockActive(isPromo = false){
        let list = this.quantityReducedToMaxStockPerProductIdAndProductAttributeId;
        if(isPromo) {
            list = this.promoQuantityReducedToMaxStockPerProductIdAndProductAttributeId;
        }

        for (let productId in list) {
            if(list.hasOwnProperty(productId)) {
                let quantityEnteredPerProductAttributeId = this._quantityPerProductAttribute(list, productId);
                for (let productAttributeId in quantityEnteredPerProductAttributeId) {
                    if(-1 === this._quantityForProductAttributeId(
                        list,
                        productId,
                        productAttributeId,
                    )) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    /**
     * @param {NaskorProduct} product
     * @param {boolean} isPromo
     */
    isReducedToMaxStockForProduct(product, isPromo = false){
        let list = this.quantityReducedToMaxStockPerProductIdAndProductAttributeId;
        if(isPromo) {
            list = this.promoQuantityReducedToMaxStockPerProductIdAndProductAttributeId;
        }

        let quantityEnteredPerProductAttributeId = this._quantityPerProductAttribute(list, product.id);
        for (let productAttributeId in quantityEnteredPerProductAttributeId) {
            if(0 < this._quantityForProductAttributeId(
                list,
                product.id,
                productAttributeId,
            )) {
                return true;
            }
        }
        return false;
    }

    /**
     * @param {boolean} isPromo
     */
    isReducedToMaxStock(isPromo = false){
        let list = this.quantityReducedToMaxStockPerProductIdAndProductAttributeId;
        if(isPromo) {
            list = this.promoQuantityReducedToMaxStockPerProductIdAndProductAttributeId;
        }

        for (let productId in list) {
            if(list.hasOwnProperty(productId)) {
                let quantityEnteredPerProductAttributeId = this._quantityPerProductAttribute(list, productId);
                for (let productAttributeId in quantityEnteredPerProductAttributeId) {
                    if(0 < this._quantityForProductAttributeId(
                        list,
                        productId,
                        productAttributeId,
                    )) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /**
     * @param {NaskorProduct} product
     * @param {NaskorProductListProductAttribute} productAttribute
     * @param {NaskorProductPromoSelectInfo} promoSelectInfo
     */
    isQuantityAutomaticallyEnteredForProductAttribute(product, productAttribute, promoSelectInfo = null) {
        const productUniqueId = Naskor.getProductUniqueId(product, promoSelectInfo);
        let list = this.quantityEnteredAutomaticallyPerProductIdAndProductAttributeId;
        if (promoSelectInfo) {
            list = this.promoQuantityEnteredAutomaticallyPerProductIdAndProductAttributeId;
        }
        return 1 === this._quantityForProductAttributeId(
            list,
            productUniqueId,
            productAttribute.id,
        );
    }

    /**
     * @param {NaskorProduct} product
     * @param {NaskorProductListProductAttribute[]} productAttributes
     * @param {NaskorProductPromoSelectInfo} promoSelectInfo
     */
    isQuantityAutomaticallyEnteredForAllProductAttributesNew(product, productAttributes, promoSelectInfo = null) {
        for(let key of Object.keys(productAttributes)) {
            if (!this.isQuantityAutomaticallyEnteredForProductAttribute(product, productAttributes[key], promoSelectInfo)) {
                return false;
            }
        }
        return true;
    }
    //
    // /**
    //  * @param {NaskorProduct} product
    //  * @param {boolean} isPromo
    //  */
    // isAllQuantityAutomaticallyEntered(isPromo = false){
    //     let list = this.quantityEnteredAutomaticallyPerProductIdAndProductAttributeId;
    //     if(isPromo) {
    //         list = this.promoQuantityEnteredAutomaticallyPerProductIdAndProductAttributeId;
    //     }
    //
    //     for (let productId in list) {
    //         if(list.hasOwnProperty(productId)) {
    //             let quantityEnteredPerProductAttributeId = this._quantityPerProductAttribute(list, productId);
    //             for (let productAttributeId in quantityEnteredPerProductAttributeId) {
    //                 if(1 !== this._quantityForProductAttributeId(
    //                     list,
    //                     productId,
    //                     productAttributeId,
    //                 )) {
    //                     return false;
    //                 }
    //             }
    //         }
    //     }
    //     return true;
    // }

    /**
     * @param {NaskorProductListProduct} product
     */
    removeAllQuantity(product) {
        product.product_attributes.map((productAttribute) => {
            const quantity = this.getQuantityForProductAttribute(product, productAttribute);
            if(quantity > 0) {
                this.enterQuantity(product, productAttribute, 0, false);
            }
        }, this);

        if(product.has_plus_promo) {
            product.plus_promo_info.promo_product.product_attributes.map((productAttribute) => {
                const quantity = this.getQuantityForProductAttribute(product, productAttribute, product.plus_promo_info);
                if (quantity > 0) {
                    this.enterPromoQuantity(product, productAttribute, 0, product.plus_promo_info);
                }
            }, this);
        }
    }

    /**
     * @param {NaskorProduct} product
     * @param {NaskorProductListProductAttribute} productAttribute
     * @param {NaskorProductPromoSelectInfo} promoSelectInfo
     */
    getQuantityForProductAttribute(product, productAttribute, promoSelectInfo = null){
        const productUniqueId = Naskor.getProductUniqueId(product, promoSelectInfo);
        let list = this.quantityEnteredPerProductIdAndProductAttributeId;
        if(promoSelectInfo) {
            list = this.promoQuantityEnteredPerProductIdAndProductAttributeId;
        }
        return this._quantityForProductAttributeId(
            list,
            productUniqueId,
            productAttribute.id,
        );
    }

    /**
     * @param {NaskorProduct} product
     * @param {boolean} isPromo
     * @deprecated use getSumQuantityForProductListProduct
     */
    getSumQuantityForProduct(product, isPromo = false) {
        let list = this.quantityEnteredPerProductIdAndProductAttributeId;
        if(isPromo) {
            list = this.promoQuantityEnteredPerProductIdAndProductAttributeId;
        }
        return this._sumQuantityForProductId(list, product.id);
    }

    /**
     * @param {NaskorProductListProduct} product
     * @param {NaskorProductPromoSelectInfo} promoSelectInfo
     */
    getSumQuantityForProductListProduct(product, promoSelectInfo = null) {
        const productUniqueId = Naskor.getProductUniqueId(product, promoSelectInfo);
        // TODO do we still need two lists? maybe just make uniqueId = productId_promoProductId
        let list = this.quantityEnteredPerProductIdAndProductAttributeId;
        if(promoSelectInfo) {
            list = this.promoQuantityEnteredPerProductIdAndProductAttributeId;
        }
        return this._sumQuantityForProductId(list, productUniqueId);
    }

    /**
     *
     * @param {NaskorProductListProduct[]} products
     * @param {boolean} isPromo
     * @return {number}
     */
    getSumQuantity(products, isPromo = false) {
        // let quantityPerProductId = this.quantityEnteredPerProductIdAndProductAttributeId;
        // if(isPromo) {
        //     quantityPerProductId = this.promoQuantityEnteredPerProductIdAndProductAttributeId;
        // }

        let sumQuantity = 0;
        for (let key of Object.keys(products)) {
            const product = products[key];
            let promoSelectInfo = null;
            
            if(product.nature === Naskor.ProductNatureBundleReference){
                /**
                 * @type {NaskorProductListBundle} bundle
                 */
                const bundle = product;
                const bundleProducts = bundle.products.concat(bundle.samples);
                bundleProducts.forEach(function (bundleProduct) {
                    sumQuantity += this.getSumQuantityForProductListProduct(bundle, bundleProduct);
                }, this);
            } else {
                if(product.has_plus_promo) {
                    promoSelectInfo = product.plus_promo_info;
                }
                sumQuantity += this.getSumQuantityForProductListProduct(product, isPromo);
            }
            
        }
        // products.forEach(function (product) {
        //     sumQuantity += this.getSum
        // });

        // for (let productId in quantityPerProductId) {
        //     if(quantityPerProductId.hasOwnProperty(productId)) {
        //         let quantityEnteredPerProductAttributeId = this._quantityPerProductAttribute(quantityPerProductId, productId);
        //         for (let productAttributeId in quantityEnteredPerProductAttributeId) {
        //             if (quantityEnteredPerProductAttributeId.hasOwnProperty(productAttributeId)) {
        //                 sumQuantity += quantityEnteredPerProductAttributeId[productAttributeId];
        //             }
        //         }
        //     }
        // }
        return sumQuantity;
    }

    /**
     * @param {NaskorProductListProduct} product
     * @param {NaskorProductPromoSelectInfo} promoSelectInfo
     */
    getMaxPromoQuantityByConditionProduct(product, promoSelectInfo = null) {
        let promoQuantityMax = 0;
        const sumProductQuantity = this.getSumQuantityForProduct(product);
        if(promoSelectInfo) {
            let promoQuantity = sumProductQuantity * parseFloat(promoSelectInfo.promo_quantity);

            if (product.has_plus_promo) {
                const plusPromoInfo = product.plus_promo_info;
                const baseQuantity = parseFloat(plusPromoInfo.base_quantity);
                promoQuantity = Math.floor(promoQuantity / baseQuantity);
                if (plusPromoInfo.promo_stock <= promoQuantity) {
                    promoQuantity = parseFloat(plusPromoInfo.promo_stock);
                }
            }

            promoQuantityMax += promoQuantity;
        } else {
            if (product.has_plus_promo) {
                const plusPromoInfo = product.plus_promo_info;
                const baseQuantity = parseFloat(plusPromoInfo.base_quantity);
                const addQuantity = parseFloat(plusPromoInfo.promo_quantity);
                promoQuantityMax = Math.floor(sumProductQuantity / baseQuantity) * addQuantity;
                if (plusPromoInfo.promo_stock <= promoQuantityMax) {
                    promoQuantityMax = parseFloat(plusPromoInfo.promo_stock);
                }
            } else if(product.nature === Naskor.ProductNatureBundleReference) {
                /**
                 * @type {NaskorProductListBundle} bundle
                 */
                const bundle = product;
                const bundleProducts = bundle.products.concat(bundle.samples);
                bundleProducts.forEach(function (bundleProduct) {
                    if(bundleProduct.selectable) {
                        promoQuantityMax += sumProductQuantity * bundleProduct.promo_quantity;
                    }
                });
                // for (let key of Object.keys(bundle.products)) {
                //     const bundleProduct = bundle.products[key];
                //     promoQuantityMax += sumProductQuantity * bundleProduct.quantity;
                // }
            }
        }
        return promoQuantityMax;
    }

    /**
     * @param {NaskorProductListProduct[]} products
     */
    getMaxPromoQuantityByConditionProducts(products) {
        let promoQuantityMax = 0;

        for (let key of Object.keys(products)) {
            promoQuantityMax += this.getMaxPromoQuantityByConditionProduct(products[key]);
        }
        return promoQuantityMax;
    }


    _getPromoSelectHeadline(isQuantityEditable, promoQuantityMax) {
        let headline = '';
        if(isQuantityEditable) {
            headline = 'Select your promotion unit';
        } else {
            headline = 'Confirm your promotion unit';
        }
        if(promoQuantityMax > 1) {
            headline += 's';
        }
        return headline;
    }

    /**
     * @param {NaskorProductListProduct} product
     * @param {int} promoQuantityMax
     */
    getPromoSelectHeadlineByConditionProduct(product, promoQuantityMax){
        if(product.nature === Naskor.ProductNatureBundleReference) {
            return ''
        } else if (product.has_plus_promo) {
            const isQuantityEditable = !this.isQuantityAutomaticallyEnteredForAllProductAttributesNew(
                product,
                product.plus_promo_info.promo_product.product_attributes,
                product.plus_promo_info
            );
            return this._getPromoSelectHeadline(isQuantityEditable, promoQuantityMax);
        }
    }

    /**
     * @param {NaskorProductListProduct[]} products
     * @param {number} promoQuantityMax
     * @return {string}
     */
    getAllPromoSelectHeadline(products, promoQuantityMax) {
        let isQuantityEditable = true;
        for (let key of Object.keys(products)) {
            const product = products[key];
            if(product.nature === Naskor.ProductNatureBundleReference) {
                return "Select your flavours";
                // /**
                //  * @type {NaskorProductListBundle} product
                //  */
                // const bundle = product;
                // for (let bundleProduct of bundle.products) {
                //     isQuantityEditable = !this.isQuantityAutomaticallyEnteredForAllProductAttributes(
                //         product,
                //         bundleProduct.product.product_attributes,
                //         true
                //     );
                //     if(isQuantityEditable) {
                //         break;
                //     }
                // }
                // if(isQuantityEditable) {
                //     break;
                // }


            } else if (product.has_plus_promo) {
                isQuantityEditable = !this.isQuantityAutomaticallyEnteredForAllProductAttributesNew(
                    product,
                    product.plus_promo_info.promo_product.product_attributes,
                    product.plus_promo_info
                );
                if(isQuantityEditable) {
                    break;
                }
            }

        }

        // const isQuantityEditable = this.isAllQuantityAutomaticallyEntered(true);

        return this._getPromoSelectHeadline(isQuantityEditable, promoQuantityMax);
    }

    /**
     * @param {NaskorBasketProductSizePosition} productSizePosition
     * @return {boolean}
     */
    static isAllPromotionProductsSelected(productSizePosition) {
        if(productSizePosition.allowed_plus_quantity !== productSizePosition.selected_plus_quantity) {
            return false;
        }
        const firstPosition = productSizePosition.positions[Object.keys(productSizePosition.positions)[0]];
        if(firstPosition.product.nature === Naskor.ProductNatureBundleReference) {
            let promoQuantityMax = 0;
            let sumPromoQuantitySelected = 0;
            let allowedProductAttributes = [];
            /**
             * @type {NaskorBasketBundlePosition} bundlePosition
             */
            const bundlePosition = firstPosition;
            const bundleProducts = bundlePosition.product.products.concat(bundlePosition.product.samples);
            bundleProducts.forEach(function (bundleProduct) {
                if(bundleProduct.selectable) {
                    promoQuantityMax += bundlePosition.quantity * bundleProduct.promo_quantity;
                    bundleProduct.promo_product.product_attributes.forEach(function (productAttribute) {
                        allowedProductAttributes[productAttribute.id] = productAttribute.id;
                        bundlePosition.productPositions.forEach(function (position) {
                            if(position.id > 0 && position.product_attribute.id === productAttribute.id) {
                                sumPromoQuantitySelected += position.quantity;
                            }
                        });
                    });

                }
            });
            const bundleBasketPositions = bundlePosition.productPositions.concat(bundlePosition.samplePositions);
            bundleBasketPositions.forEach(function (position) {
                if(!position.product_attribute.id in allowedProductAttributes) {
                    return false;
                }
            });

            const promoQuantityToSelect = promoQuantityMax - sumPromoQuantitySelected;
            return promoQuantityToSelect === 0;
        }
        return true;
    }
}

class NaskorProductListFilterSelected {

    /**
     * @param {NaskorProductListFilterSelectedData[]} naskorProductListFilterSelectedData
     * @param {NaskorProductListFilter[]} naskorProductListFilter
     */
    constructor(naskorProductListFilterSelectedData, naskorProductListFilter) {
        this.filterSelectedData = naskorProductListFilterSelectedData;
        this.filterData = naskorProductListFilter;
        /**
         * @type {NaskorProductListFilter}
         */
        this.mobileActiveFilterItem = null;
    }

    /**
     * @param {NaskorProductListFilter} filter
     *
     * @return NaskorProductListFilterSelectedData
     * @private
     */
    _getSelectedDataForFilter(filter) {
        return this.filterSelectedData.find(element => element.key === filter.key);
    }

    /**
     * @param {NaskorProductListFilter} filter
     * @param {NaskorProductListFilterOption} option
     * @return {string}
     */
    getFilterOptionKey(filter, option){
        return (option.id > 0 ? option.id : filter.options.findIndex(element => element.name === option.name))+'';
    }

    /**
     * @param {NaskorProductListFilter} filter
     * @param {boolean} isParentActive
     */
    isFilterActive(filter, isParentActive = true) {
        const filterSelected = this._getSelectedDataForFilter(filter);
        return isParentActive && (filterSelected === undefined ? false : filterSelected.active);
    }

    /**
     * @param {NaskorProductListFilter} filter
     * @param {NaskorProductListFilterOption} option
     */
    isFilterOptionSelected(filter, option) {
        const filterSelected = this._getSelectedDataForFilter(filter);
        const selectedOptionKeys = filterSelected === undefined ? [] : filterSelected.selected;
        return selectedOptionKeys.includes(this.getFilterOptionKey(filter, option));
    }

    /**
     * @param {NaskorProductListFilter} filter
     * @param {NaskorProductListFilterOption} option
     * @param {NaskorProductListFilter} childFilter
     */
    selectFilterOption(filter, option, childFilter) {
        const filterSelectedData = this.filterSelectedData;
        const keyIndex = filterSelectedData.findIndex(element => element.key === filter.key);
        const optionKey = this.getFilterOptionKey(filter, option);

        if(!filterSelectedData.find(element => element.key === filter.key)) {
            this.filterSelectedData.push({
                key: filter.key,
                selected: []
            });
        }

        // if childFilter set and filter does not allowMultiple, reset all other child filters of filter
        if(childFilter && !filter.allowMultiple && filterSelectedData.find(element => element.key === childFilter.key)) {
            const childKeyIndex = filterSelectedData.findIndex(element => element.key === childFilter.key);
            this.filterSelectedData[childKeyIndex].selected = [];
        }

        // if any selected filter option has this filter as forbidden => deselect selected filter
        for(const [filterSelectedKeyIndex, filterSelected] of this.filterSelectedData.entries()) {
            if (filterSelected.selected.length > 0) {
                const filterKeyIndex = this.filterData.findIndex(element => element.key === filterSelected.key);
                for (let filterOptionSelectedKey of filterSelected.selected) {
                    if (this.filterData[filterKeyIndex]) {
                        const optionIndex = this.filterData[filterKeyIndex].options.findIndex(element => (element.id > 0 && element.id + '' === filterOptionSelectedKey) || element.name === filterOptionSelectedKey);
                        if (typeof(this.filterData[filterKeyIndex].options[optionIndex]) != 'undefined') {
							if (this.filterData[filterKeyIndex].options[optionIndex].forbiddenFilter) {
								if (this.filterData[filterKeyIndex].options[optionIndex].forbiddenFilter.includes(filter.key)) {
									// console.log('forbidden combination, deselect ' + this.filterData[filterKeyIndex].name);
									this.filterSelectedData[filterSelectedKeyIndex].selected = [];
								}
							}
						}
                    }
                }
            }
        }

        // if this option has any selected filter as forbidden => deselect selected filter
        if(option.forbiddenFilter && option.forbiddenFilter.length > 0) {
            for (let filterForbiddenKey of option.forbiddenFilter) {
                const filterForbiddenKeySelectedIndex = filterSelectedData.findIndex(element => element.key === filterForbiddenKey);
                if(this.filterSelectedData[filterForbiddenKeySelectedIndex]) {
                    this.filterSelectedData[filterForbiddenKeySelectedIndex].selected = [];
                }
            }
        }


        if(!filter.allowMultiple) {
            this.filterSelectedData[keyIndex].selected = [];
        }
        this.filterSelectedData[keyIndex].selected.push(optionKey);
    }

    /**
     * @param {NaskorProductListFilter} filter
     * @param {NaskorProductListFilterOption} option
     */
    deselectFilterOption(filter, option) {
        const filterSelectedData = this.filterSelectedData;
        const keyIndex = filterSelectedData.findIndex(element => element.key === filter.key);
        const optionKey = this.getFilterOptionKey(filter, option);

        this.filterSelectedData[keyIndex].selected = this.filterSelectedData[keyIndex].selected.filter(e => e !== optionKey);
    }

    getSelectedOptionsPerKeyCommaSeparated() {
        let params = {};
        for(let filterSelected of this.filterSelectedData) {
            if(filterSelected.selected.length > 0) {
                params[filterSelected.key] = filterSelected.selected.join();
            }
        }
        return params;
    }

    /**
     * @param {NaskorProductListFilter} filter
     */
    setMobileActiveFilter(filter) {
        this.mobileActiveFilterItem = filter;
    }

    /**
     * @param {NaskorProductListFilter} filter
     */
    isMobileFilterActive(filter) {
        return !this.isNonMobileFilterActive()
            && this.mobileActiveFilterItem.key === filter.key
            && this.mobileActiveFilterItem.name === filter.name;
    }

    isNonMobileFilterActive() {
        return this.mobileActiveFilterItem === null;
    }
}


interface NaskorBrand {
    id: number;
    name: string;
}

interface NaskorProduct {
    id: number;
    name: string;
    nature: number;
    brand: NaskorBrand;
    image_uri: string;
    image_overlay_uri: string;
    unit_value: number;
    unit: string;
    price_currency: string;
}

interface NaskorProductWithProductAttributes extends NaskorProduct{
    product_attributes: NaskorProductAttribute[];
    price_is_red: boolean;
    price_show_regular: boolean;
    promo_text: string;
    has_available_shortly_promo: boolean;
}

interface NaskorProductPromoSelectInfo{
    promo_quantity: number;
    promo_stock: number;
    promo_product: NaskorProductWithProductAttributes;
}

interface NaskorProductPlusPromoInfo extends NaskorProductPromoSelectInfo {
    promo_id: number;
    base_quantity: number;
}

interface NaskorProductInfoSellSheet {
    image: boolean;
    youtube: boolean;
}
interface NaskorProductInfoSellSheetImage {
    imagePath: string;
}
interface NaskorProductInfoSellSheetYoutube {
    link: string;
}

interface NaskorProductInfo {
    id: number;
    name: string;
    // nutritionFacts: NaskorProductInfoProductAttributeNutritionFact[];
    sellSheets: NaskorProductInfoSellSheet[];
}

interface NaskorProductAttribute {
    id: number;
    name: string;
    has_stock: boolean;
    stock: number;
    eta: string;
    has_notify_me: boolean;
}

interface NaskorCategory {
    id: number;
    name: string;
}

interface NaskorCountry {
    id: number;
    name: string;
}

interface NaskorCarrier {
    id: number;
    name: string;
}

interface NaskorVatInfo {
    vatRate: number;
    vatFrom: number;
    vatValue: number;
}


interface NaskorShippingInfo {
    carrier: NaskorCarrier;
    ship_type_id: number;
    ship_units: number;
    price_calculated: boolean;
    price_excl_vat: number;
    vat_info: NaskorVatInfo[];
}

interface NaskorShippingAddress {
    id: number;
    default: boolean;
    // title: string;
    firstname: string;
    lastname: string;
    company: string;
    street: string;
    street2: string;
    number: string;
    zip: string;
    city: string;
    country: NaskorCountry;
    // mail: string;
    // phone: string;

}




interface NaskorBasketPositionProduct extends NaskorProduct {

}
interface NaskorBasketPositionProductBundle extends NaskorProductBundle,NaskorBasketPositionProduct {

}

interface NaskorBasketPositionProductAttribute extends NaskorProductAttribute {

}

interface NaskorBasketProductSizePosition{
    positions: NaskorBasketPosition[];
    allowed_plus_quantity: number;
    selected_plus_quantity: number;
    condition_products: NaskorProductListProduct[];


    // quantity: number; // 3+1 => 1
    // product: NaskorProductWithProductAttributes; // => plus produkt
}

interface NaskorBasketPosition{
    id: number;
    is_editable: boolean;
    quantity: number;
    quantity_requested: number;
    plus_quantity: number;
    plus_quantity_requested: number;
    product_label_available: boolean;
    is_deposit: boolean;
    deposit_price: number;
    single_price_excl_vat: number;
    total_price_excl_vat: number;

    price_show: boolean;
    single_price_regular_excl_vat: number;
    total_price_regular_excl_vat: number;

    total_price_plus_excl_vat: number;
    stock_per_expire_date: number[];
    product_attribute: NaskorBasketPositionProductAttribute;
    product: NaskorBasketPositionProduct;

    price_is_red: boolean;
    price_show_regular: boolean;
    promo_text: string;

	show_product_size_link: boolean;
}

interface NaskorBasketBundlePosition extends NaskorBasketPosition{
    product: NaskorBasketPositionProductBundle;
    productPositions: NaskorBasketPosition[];
    samplePositions: NaskorBasketPosition[];
}

interface NaskorBasketUser {
    defaultShippingCountry: NaskorCountry;
    shippingAddresses: NaskorShippingAddress[];
}

interface NaskorBasketVoucherCode {
    id: number;
    code: string;
    text: string;
}

interface NaskorPaymentType {
    id: number;
    name: string;
}

interface NaskorPaymentInstrument {
	paymentInstrumentId: string;
	attributes: {
		cardNumber: string;
		brand: string;
		expiryMonth: string;
		expiryYear: string;
	};
}

interface NaskorBasket {
    id: number;
    user: NaskorBasketUser;
    shippingAddress: NaskorShippingAddress;
    positions: NaskorBasketPosition[];
    position_by_product_size: NaskorBasketProductSizePosition[];
    price_currency: string;
    position_price_excl_vat: number;
    position_vat_info: NaskorVatInfo[];
    total_deposit: number;
    is_product_label_service: boolean;
    product_label_price_excl_vat: number;
    product_label_service_vat_info: NaskorVatInfo[];
    shipping_info: NaskorShippingInfo;
    // total_price_regular_incl_vat: number;
    total_price_savings_excl_vat: number;
    total_price_incl_vat: number;
    total_weight_in_gram: number;
    unit_count: number;
    reference: string;
	merchantId: string;
	showButtonPromoQtyRecalculate: boolean;
    voucherCodes: NaskorBasketVoucherCode[];
	paymentTypeTerms: NaskorPaymentType;
	paymentTypeBankTransfer: NaskorPaymentType;
	paymentTypeCreditCard: NaskorPaymentType;
	paymentType: NaskorPaymentType;
	paymentInstrumentReference: string;
	paymentInstruments: NaskorPaymentInstrument[];
	creditCardFeePrice: number;
	creditCardFeePercent: number;
	creditCardFeeVatPercent: number;
	creditCardFeePriceInc: number;
	creditCardFeeVatPrice: number;
	totalPriceInclVatAndExclCreditCardFee: number;
	isCreditCardPaymentActive: boolean;
  basket_place_order_error?: string;
	selectableShippingAddressCountries:NaskorCountry[];
	showButtonOnlyIncompletePositions: boolean;
	onlyIncompletePositions: boolean;
	showOnlyIncompletePositionsAfterFileUpload: boolean;
	hasApiAccess: boolean;
	user_has_unpaid_invoices: boolean;
}

interface NaskorNutritionFactsLanguage {
    locale: string;
    name: string;
    default: boolean;
}

interface NaskorProductListSortByValue {
    name: string;
    value: string;
}

interface NaskorProductListPerPageValue {
    name: string;
    value: string;
}

interface NaskorProductListFilterSelectedData {
    key: string;
    active: boolean;
    selected: number[];
}

interface NaskorProductListFilterOption {
    id: number;
    name: string;
    childFilter: NaskorProductListFilter;
    forbiddenFilter: string[];
}

interface NaskorProductListFilter {
    key: string;
    name: string;
    allowSearch: boolean;
    allowMultiple: boolean;
    options: NaskorProductListFilterOption[];
}

interface NaskorProductListProductAttribute extends NaskorProductAttribute {
    has_nutritionFacts: boolean;
    nutritionFactLocales: string[];
}

interface NaskorProductListShopProduct {
    id: number;
    products: NaskorProductListProduct[];

}

interface NaskorProductBundleProduct extends NaskorProductPromoSelectInfo{
    selectable: boolean;
}

interface NaskorProductBundle {
    products: NaskorProductBundleProduct[];
    samples: NaskorProductBundleProduct[];
}

interface NaskorProductListBundle extends NaskorProductBundle, NaskorProductListProduct {
    // start: string;
    // end: string;
}

interface NaskorProductListProduct extends NaskorProductWithProductAttributes {
    category: NaskorCategory;
    is_new: boolean;
    is_favourite: boolean;
    is_favourite_allowed: boolean;
    price: number;
    price_regular: number;
    vpe: number;
    msrp: number;
    has_productInfo: boolean;
    is_deposit: boolean;
    deposit_price: number;
    has_plus_promo: boolean;
    plus_promo_info: NaskorProductPlusPromoInfo;
    product_attributes: NaskorProductListProductAttribute[];
}

interface NaskorProductList {
    activePage: number;
    pages: number;
    count: number;
    total: number;
    isLoggedIn: boolean;
    isLoaded: boolean;
    filter: NaskorProductListFilter[];
    filterSelected: NaskorProductListFilterSelectedData[];
    products: NaskorProductListProduct[];
    shopProducts: NaskorProductListShopProduct[];
    perPageValues: NaskorProductListPerPageValue[];
    sortByValues: NaskorProductListSortByValue[];
    selectedPerPageValue: number;
    selectedSortByValue: string;
    scrollToProductId: number;
    basketId: number;
    titleParts: string[];
    noResultText: string;
    nutritionFactsLanguages: NaskorNutritionFactsLanguage[];
}

interface NaskorSearchResult {
    brands: NaskorBrand[];
    products: NaskorProduct[];
}

export {
    Naskor,
    NaskorEnteredQuantity,
    NaskorProductListFilterSelected,
    NaskorBrand,
    NaskorProduct,
    NaskorProductPlusPromoInfo,
    NaskorProductPromoSelectInfo,
    NaskorProductWithProductAttributes,
    NaskorProductAttribute,
    NaskorCountry,
    NaskorBasket,
    NaskorBasketUser,
    NaskorBasketPosition,
    NaskorBasketProductSizePosition,
    NaskorShippingAddress,
    NaskorBasketPositionProduct,
    NaskorBasketPositionProductAttribute,
    NaskorBasketVoucherCode,
    NaskorProductList,
    NaskorProductListFilter,
    NaskorProductListFilterOption,
    NaskorProductListFilterSelectedData,
    NaskorProductListPerPageValue,
    NaskorProductListProduct,
    NaskorProductListShopProduct,
    NaskorProductListProductAttribute,
    NaskorProductListBundle,
    NaskorNutritionFactsLanguage,
    NaskorProductInfo,
    // NaskorProductInfoProductAttributeNutritionFact,
    NaskorProductInfoSellSheet,
    NaskorProductInfoSellSheetImage,
    NaskorProductInfoSellSheetYoutube,
    NaskorSearchResult,
	NaskorVatInfo,
};